<template>
  <div>
    <Loader></Loader>
    <Modal v-if="$store.state.showModal"></Modal>
    <ModalForm v-if="$store.state.showModalForm"></ModalForm>
    <AppHeader></AppHeader>
    <main class="main">
      <router-view></router-view>
    </main>
    <AppFooter></AppFooter>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";
const AppHeader = defineAsyncComponent(() =>
  import("./views/shared/AppHeader.vue")
);
const AppFooter = defineAsyncComponent(() =>
  import("./views/shared/AppFooter.vue")
);

import Loader from "./components/Loader.vue";
const Modal = defineAsyncComponent(() => import("./components/Modal.vue"));
const ModalForm = defineAsyncComponent(() =>
  import("./components/ModalForm.vue")
);

export default {
  components: {
    AppHeader,
    AppFooter,
    Loader,
    Modal,
    ModalForm,
  },
  created() {
    window.document.title = process.env.VUE_APP_PAGE_TITLE;
  },
};
</script>

<style lang="scss">
.main {
  background: $primary-light;
}

.ps {
  max-height: 75vh;
  &__rail-y {
    opacity: 1 !important;
    width: 6px !important;
  }
  &__thumb-y {
    width: 6px !important;
  }
}
</style>
